import React from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';

import Layout from '../components/layout'
import SEO from '../components/seo'

import { makeStyles, createStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => createStyles({
  root: {
    flexGrow: 1,
    minHeight: '0vh',
    backgroundColor: "#EEE",
    paddingTop:'0px',
    marginTop:'0px',
  },
}));

function Coming(props) {
  const classes = useStyles();

  return(
    <Layout>
      <SEO title="Coming Soon" />
        <Grid container component="main" className={classes.root} location={props.location.pathname}>
      </Grid>
    </Layout>
  )
}

Coming.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(Coming);